<template>
  <div class="client-products client-page">
    <!-- <el-tabs v-model="tabsName" class="client-products-tabs" type="border-card">
      <el-tab-pane label="配件" name="parts">
        <Parts />
      </el-tab-pane>
      <el-tab-pane label="服务项目"></el-tab-pane>
      <el-tab-pane label="套餐"></el-tab-pane>
    </el-tabs> -->
    <AccountStatus v-if="showAccountStatus" />
    <Parts v-if="!showAccountStatus" />
  </div>
</template>

<script>
import Parts from "./components/Parts";
import AccountStatus from "@/components/AccountStatus";
export default {
  name: "ClientProducts",
  components: {
    Parts,
    AccountStatus
  },
  data() {
    return {
      desc: "浏览商品",
      tabsName: "parts"
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters["user/userInfo"];
    },
    companyInfo() {
      return this.$store.getters["companyInfo/companyInfo"];
    },
    showAccountStatus() {
      if (this.companyInfo.setting.allowInformalCustomer) {
        return false;
      } else {
        if (this.userInfo.auditStatus !== "审核通过" && localStorage.isLogin === "true") {
          return true;
        }
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.client-products {
  /deep/ .client-products-tabs {
    display: flex;
    flex-direction: column;
    height: calc(100% - 2px);
    overflow: hidden;
    .el-tabs__content {
      flex: 1;
      padding: 10px;
      overflow: auto;
      .el-tab-pane {
        height: 100%;
      }
    }
  }
  /deep/ .client-products-tabs > .el-tabs__content {
    padding: 0;
    background-color: #e7ebed;
  }
}
</style>
