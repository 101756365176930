<template>
  <el-dialog
    title="高级查询设置(按住列标题拖动设置隐藏 或 显示顺序调整）"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="550px"
    @close="close"
  >
    <div class="content-box">
      <div>常规查询条件</div>
      <div class="flex m-t-10">
        <div class="table-left flex1">
          <table border="1" cellspacing="0" cellpadding="0">
            <thead>
              <tr>
                <th>隐藏项</th>
              </tr>
            </thead>
            <tbody>
              <draggable
                v-model="hiddenList"
                handle=".row-move"
                chosen-class="chosen"
                force-fallback="true"
                group="people"
                animation="300"
              >
                <tr v-for="item in hiddenList" :key="item.id" class="row-move">
                  <td>{{ item.name }}</td>
                </tr>
              </draggable>
            </tbody>
          </table>
        </div>
        <div class="table-right flex1 m-l-20">
          <table border="1" cellspacing="0" cellpadding="0">
            <thead>
              <tr>
                <th>显示项</th>
              </tr>
            </thead>
            <tbody>
              <draggable
                v-model="showList"
                handle=".row-move"
                chosen-class="chosen"
                force-fallback="true"
                group="people"
                animation="300"
              >
                <tr v-for="item in showList" :key="item.id" :class="item.isMove ? 'row-move' : ''">
                  <td>{{ item.name }}</td>
                </tr>
              </draggable>
            </tbody>
          </table>
        </div>
      </div>
      <div class="m-t-10">默认值生效范围</div>
      <div class="m-t-10 flex flex-row-center">
        <el-popover
          placement="top-start"
          width="350"
          trigger="hover"
          content="启用后「所属仓库，配件性质，仅查询有库存记录的商品」设置内容对快速查询同等生效"
        >
          <i slot="reference" class="el-icon-question text-warning"></i>
        </el-popover>
        <span class="m-l-10">快速查询同步应用默认设置</span>
        <el-switch class="m-l-10" v-model="keywordSearchUseDefault"> </el-switch>
      </div>
    </div>
    <span slot="footer">
      <el-button size="mini" type="primary" plain @click="confirm">确定(F3)</el-button>
      <el-button size="mini" type="primary" plain @click="close">取消(Esc)</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getAccountsSetting, updateAccountsSetting } from "@/api/base";

import draggable from "vuedraggable";
let lastKeyScope;
export default {
  name: "AdvancedSettingBox",
  components: {
    draggable
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    ALL_LIST: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      desc: "浏览商品-配件-高级查询设置-参数设置",
      dialogVisible: false,
      keywordSearchUseDefault: true,
      keyScope: "advancedSettingBox",
      showList: [],
      hiddenList: [],
      info: {}
    };
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
      if (val) {
        lastKeyScope = this.$hotkeys.getScope();
        this.$hotkeys.setScope(this.keyScope);
        this.bindKeyboard();
        this.getAccountsSetting();
      } else {
        this.$hotkeys.deleteScope(this.keyScope);
        this.$hotkeys.setScope(lastKeyScope);
      }
    },
    dialogVisible() {
      this.$emit("update:visible", this.dialogVisible);
    }
  },
  methods: {
    async getAccountsSetting() {
      const data = await getAccountsSetting();
      this.info = data;
      if (data && data.generalSearch && data.generalSearch.showItems) {
        // 处理显示列表
        const showList = [];
        data.generalSearch.showItems.map(item => {
          for (const items of this.ALL_LIST) {
            if (item === items.id) {
              showList.push({
                ...items,
                isMove: !["code", "name"].includes(items.id)
              });
              break;
            }
          }
        });
        this.showList = showList;
      }
      if (data && data.generalSearch && data.generalSearch.hideItems) {
        // 处理隐藏列表
        const hiddenList = [];
        data.generalSearch.hideItems.map(item => {
          for (const items of this.ALL_LIST) {
            if (item === items.id) {
              hiddenList.push({
                ...items,
                isMove: !["code", "name"].includes(items.id)
              });
              break;
            }
          }
        });
        this.hiddenList = hiddenList;
      }
      this.keywordSearchUseDefault = data.generalSearch.keywordSearchUseDefault;
    },
    bindKeyboard() {
      this.$hotkeys("f3", { scope: this.keyScope }, (e, handler) => {
        switch (handler.key) {
          case "f3":
            e.preventDefault();
            this.confirm();
            break;
          default:
            return;
        }
      });
    },
    close() {
      this.dialogVisible = false;
    },
    confirm() {
      const data = this.info;
      data.generalSearch.hideItems = this.hiddenList.map(item => item.id);
      data.generalSearch.showItems = this.showList.map(item => item.id);
      data.generalSearch.keywordSearchUseDefault = this.keywordSearchUseDefault;
      updateAccountsSetting(data)
        .then(() => {
          this.$message.success("设置成功！");
          this.$emit("setShowList", this.showList);
          this.close();
        })
        .catch(err => {
          this.$message.error(err.message || "更新失败");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.content-box {
  background-color: white;
  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #aeaeae;
    tbody > div {
      height: 300px;
    }
    th {
      border: 0;
      width: 100%;
      padding: 5px;
      font-size: 16px;
      text-align: left;
      display: block;
      box-sizing: border-box;
      background-color: #dbdbdb;
      border-bottom: 1px solid #aeaeae;
    }
    td {
      border: 0;
      width: 100%;
      padding: 5px;
      font-size: 14px;
      display: block;
      box-sizing: border-box;
    }
    tr {
      display: block;
      background-color: white;
    }
    tr:hover {
      background-color: rgba(101, 137, 248, 0.2) !important;
    }
    .row-move {
      cursor: move;
    }
    tbody {
      tr {
        border-bottom: 1px solid #aeaeae;
      }
    }
  }
}
</style>
