<template>
  <div
    class="parts-category-search flex w-200 min-w-200 m-lr-5 m-tb-5"
    :class="isUnfold ? '' : 'parts-category-search-unfold'"
  >
    <div class="flex1 display-block" :class="isUnfold ? '' : 'display-none'">
      <el-tabs v-model="activeName">
        <el-tab-pane label="配件分类" name="one">
          <el-input v-model="categoryName" clearable size="mini" placeholder="配件分类名称"></el-input>
          <el-tree
            ref="tree"
            :data="treeData"
            node-key="id"
            highlight-current
            :props="defaultProps"
            :filter-node-method="filterNode"
            :check-on-click-node="true"
            :expand-on-click-node="false"
            :default-expanded-keys="defaultExpandedKeys"
            @check="checkChange"
          >
          </el-tree>
        </el-tab-pane>
        <el-tab-pane v-if="false" label="批量查询" name="two">
          <el-input
            v-if="batchData.length === 0"
            v-model="batchQueryValue"
            type="textarea"
            :placeholder="placeholder"
            rows="8"
            @paste.native="batchPasteHandle"
          >
          </el-input>
          <div v-if="batchData.length > 0">
            <el-table :data="batchData" border class="width-full">
              <el-table-column
                v-for="(column, index) in batchTitle"
                :key="index"
                :prop="column.prop"
                :label="column.name"
                :min-width="column.width"
              >
                <template slot-scope="scope">
                  <template>{{ scope.row[column.prop] }}</template>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="m-t-10 flex">
            <el-button
              @click="batchCancelHandle"
              class="flex1 m-r-10"
              type="danger"
              size="mini"
              v-if="batchData.length > 0"
            >
              重新粘贴
            </el-button>
            <el-button @click="batchQuery" class="flex1" type="primary" size="mini">查询</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div
      @click="unfoldPackUp()"
      class="font-size-18 cursor-pointer unfold-pack-up"
      :class="isUnfold ? '' : 'border-left-0'"
    >
      <i v-if="isUnfold" class="el-icon-s-fold"></i>
      <i v-else class="el-icon-s-unfold"></i>
    </div>
    <div v-if="!isUnfold" class="width-full m-t-30">
      <div class="writing-vertical-rl flex flex-center width-full m-t-5">
        <div v-if="currentId !== null" class="w-10 h-10 border-radius-10 bg-primary"></div>
        <div @click="unfoldPackUp('one')" class="font-size-14 letter-spacing-5 m-t-5 white-space-nowrap cursor-pointer">
          配件分类
        </div>
      </div>
      <el-divider></el-divider>
      <div v-if="false" class="writing-vertical-rl flex flex-center width-full">
        <div v-if="batchData.length > 0" class="w-10 h-10 border-radius-10 bg-primary"></div>
        <div @click="unfoldPackUp('two')" class="font-size-14 letter-spacing-5 m-t-5 white-space-nowrap cursor-pointer">
          批量查询
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import parsingCodesData from "@/utils/parsingCodesData";
import { getCategoriesTree } from "@/api/base";
export default {
  name: "PartsCategorySearch",
  data() {
    return {
      desc: "浏览商品-配件-配件分类树型组件",
      activeName: "one",
      isUnfold: true,
      placeholder: `批量查询说明：\n1、从Excel文件中选择件号（超过两列，只识别前2列）。\n2、也可以复制一列，数量默认1。\n3、选中本框执行【粘贴】操作。\n4、查看粘贴结果，做查询前调整。\n5、点击「查询」按钮进行查询`,
      categoryName: "",
      treeData: [],
      defaultProps: {
        children: "children",
        label: "name"
      },
      currentId: null,
      batchQueryValue: "",
      batchTitle: [
        { prop: "code", name: "配件编码", width: "70%" },
        { prop: "num", name: "数量", width: "30%" }
      ],
      batchData: [],
      defaultExpandedKeys: ["all"]
    };
  },
  watch: {
    categoryName() {
      this.$refs.tree.filter(this.categoryName);
    }
  },
  mounted() {
    this.getCategoryList();
  },
  methods: {
    resetCategory() {
      this.$refs.tree.setCheckedNodes([]);
    },
    getCategoryList() {
      getCategoriesTree()
        .then(res => {
          if (res) {
            const treeData = {
              id: "all",
              name: "全部分类",
              disabled: true,
              children: [...res]
            };
            this.treeData = [treeData];
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    unfoldPackUp(type) {
      if (type) {
        this.activeName = type;
      }
      this.isUnfold = !this.isUnfold;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    checkChange(val) {
      let name = val.name;
      if (this.currentId === val.id) {
        this.currentId = null;
        name = "";
        this.$refs.tree.setCheckedNodes([]);
      } else {
        this.currentId = val.id;
        this.$refs.tree.setCheckedNodes([val]);
      }
      this.$emit("check", val.name);
      this.$emit("change", {
        id: this.currentId,
        category: name
      });
    },
    batchQuery() {
      this.$emit("batchQuery", this.batchQueryValue, this.batchData);
    },
    batchCancelHandle() {
      this.batchQueryValue = "";
      this.batchData = [];
    },
    batchPasteHandle(e) {
      const listArr = parsingCodesData(e.clipboardData.getData("text/plain"));
      if (!listArr.length) {
        this.$message.warning("未检测到有效的粘贴内容");
        return false;
      }
      this.batchData = listArr;
    }
  }
};
</script>

<style lang="scss" scoped>
.parts-category-search {
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /deep/ .el-tabs {
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    .el-tabs__header {
      margin-bottom: 0;
    }
    .el-tabs__nav {
      width: calc(100% - 30px);
      display: flex;
      .el-tabs__item {
        flex: 1;
        padding: 0;
        height: 30px;
        line-height: 30px;
        text-align: center;
      }
    }
    .el-tabs__content {
      flex: 1;
      overflow: auto;
      padding: 5px !important;
    }
  }
  .unfold-pack-up {
    top: 0;
    right: 0;
    height: 30px;
    padding: 0 5px;
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 0;
    border-right: 0;
    box-sizing: border-box;
    line-height: 30px;
    &.border-left-0 {
      border-left: 0;
    }
  }
  /deep/ .el-tree {
    .el-tree-node__expand-icon {
      color: #000000;
      font-size: 16px;
    }
    .el-tree-node__expand-icon.is-leaf {
      color: transparent;
    }
    .is-current .el-tree-node__content {
      background-color: transparent;
    }
    .is-current.is-checked > .el-tree-node__content {
      color: #6589f8;
      background-color: #f0f7ff;
    }
  }
  .el-divider {
    margin: 10px 0;
  }
}
.parts-category-search-unfold {
  width: 30px;
  min-width: 30px;
}
</style>
