<template>
  <el-dialog
    title="高级查询"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="550px"
    @close="close"
  >
    <div class="content-box">
      <div class="flex flex-center">
        <span class="flex1">常规字段</span>
        <el-button v-if="!noLoginLook" type="text" @click="clickDefaultCursor">默认光标</el-button>
        <el-button type="text" @click="clickResetCondition">重置条件</el-button>
      </div>
      <div class="content-box-form">
        <el-form ref="form" :model="formData" label-width="90px" size="mini">
          <template v-for="item in showList">
            <el-form-item label="配件编码：" :key="item.id" v-if="item.id === 'code'">
              <el-input
                ref="code"
                v-model="formData.code"
                clearable
                placeholder="编码/图号/标准编码/供应商"
                @blur="formValidate({ code: formData.code })"
                @focus="formFocus('code')"
              >
                <el-select slot="append" v-model="formData.codeMatch" style="width: 100px">
                  <el-option v-for="(name, key) in PART_CODE_WAY" :key="key" :label="name" :value="key"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item label="配件名称：" :key="item.id" v-if="item.id === 'name'">
              <el-autocomplete
                ref="name"
                v-model="formData.name"
                clearable
                :fetch-suggestions="autocompleteSearchPartName"
                :trigger-on-focus="false"
                highlight-first-item
                placeholder="名称/英文名称/标准名称"
                class="width-full"
                @blur="formValidate({ name: formData.name })"
                @focus="formFocus('name')"
              >
                <el-select slot="append" v-model="formData.nameMatch" style="width: 100px">
                  <el-option v-for="(name, key) in PART_CODE_WAY" :key="key" :label="name" :value="key"></el-option>
                </el-select>
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="车型：" :key="item.id" v-if="item.id === 'vehModel'">
              <el-autocomplete
                ref="vehModel"
                v-model="formData.vehModel"
                clearable
                :fetch-suggestions="autocompleteSearchVehModel"
                :trigger-on-focus="false"
                highlight-first-item
                placeholder="适用车型/发动机号"
                class="width-full"
                @blur="formValidate({ vehModel: formData.vehModel })"
                @focus="formFocus('vehModel')"
              >
                <el-select slot="append" v-model="formData.vehModelMatch" style="width: 100px">
                  <el-option v-for="(name, key) in PART_CODE_WAY" :key="key" :label="name" :value="key"></el-option>
                </el-select>
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="配件品牌：" :key="item.id" v-if="item.id === 'brand'">
              <el-autocomplete
                ref="brand"
                v-model="formData.brand"
                clearable
                :fetch-suggestions="autocompleteSearchPartBrand"
                :trigger-on-focus="false"
                highlight-first-item
                placeholder="配件品牌"
                class="width-full"
                @blur="formValidate({ brand: formData.brand })"
                @focus="formFocus('brand')"
              >
                <el-select slot="append" v-model="formData.brandMatch" style="width: 100px">
                  <el-option v-for="(name, key) in PART_CODE_WAY" :key="key" :label="name" :value="key"></el-option>
                </el-select>
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="产地：" :key="item.id" v-if="item.id === 'productionPlace'">
              <el-autocomplete
                ref="productionPlace"
                v-model="formData.productionPlace"
                clearable
                :fetch-suggestions="autocompleteSearchProductionPlace"
                :trigger-on-focus="false"
                highlight-first-item
                placeholder="产地"
                class="width-full"
                @blur="formValidate({ productionPlace: formData.productionPlace })"
                @focus="formFocus('productionPlace')"
              >
                <el-select slot="append" v-model="formData.productionPlaceMatch" style="width: 100px">
                  <el-option v-for="(name, key) in PART_CODE_WAY" :key="key" :label="name" :value="key"></el-option>
                </el-select>
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="生产码：" :key="item.id" v-if="item.id === 'productionCode'">
              <el-input
                ref="productionCode"
                v-model="formData.productionCode"
                clearable
                placeholder="生产码"
                @blur="formValidate({ productionCode: formData.productionCode })"
                @focus="formFocus('productionCode')"
              >
                <el-select slot="append" v-model="formData.productionCodeMatch" style="width: 100px">
                  <el-option v-for="(name, key) in PART_CODE_WAY" :key="key" :label="name" :value="key"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item label="部位码：" :key="item.id" v-if="item.id === 'posCode'">
              <el-input
                ref="posCode"
                v-model="formData.posCode"
                clearable
                placeholder="部位码"
                @blur="formValidate({ posCode: formData.posCode })"
                @focus="formFocus('posCode')"
              >
                <el-select slot="append" v-model="formData.posCodeMatch" style="width: 100px">
                  <el-option v-for="(name, key) in PART_CODE_WAY" :key="key" :label="name" :value="key"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item label="配件性质：" :key="item.id" v-if="item.id === 'property'">
              <el-col :span="18">
                <ht-select
                  ref="property"
                  v-model="formData.property"
                  :options="propertiesOptions"
                  filterZero
                  clearable
                  collapse-tags
                  filterable
                  multiple
                  has-selecte-all-val
                  placeholder="配件性质"
                >
                </ht-select>
              </el-col>
              <el-col v-if="!noLoginLook" :span="6" class="text-center">
                <el-button @click="clickSetDefaultProperty" type="text">默认值设置</el-button>
              </el-col>
            </el-form-item>
            <el-form-item label="所属仓库：" :key="item.id" v-if="item.id === 'warehouse'">
              <el-col :span="18">
                <ht-select
                  ref="warehouse"
                  v-model="formData.warehouse"
                  :options="warehouseOptions"
                  id="depotNo"
                  name="depotName"
                  filterZero
                  clearable
                  collapse-tags
                  filterable
                  multiple
                  has-selecte-all-val
                  placeholder="所属仓库"
                >
                </ht-select>
              </el-col>
              <el-col v-if="!noLoginLook" :span="6" class="text-center">
                <el-button @click="clickSetDefaultWarehouse" type="text">默认值设置</el-button>
              </el-col>
            </el-form-item>
            <el-form-item label="规格：" :key="item.id" v-if="item.id === 'model'">
              <el-input ref="model" v-model="formData.model" clearable placeholder="规格" @focus="formFocus('model')">
                <el-select slot="append" v-model="formData.modelMatch" style="width: 100px">
                  <el-option v-for="(name, key) in PART_CODE_WAY" :key="key" :label="name" :value="key"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </template>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="flex flex-center">
      <el-button v-if="!noLoginLook" size="mini" type="primary" plain icon="el-icon-setting" @click="setting">
        设置
      </el-button>
      <el-button size="mini" type="primary" plain @click="confirm" ref="confirm" @focus="formFocus('confirm')">
        确定(F3)
      </el-button>
      <el-button size="mini" type="primary" plain @click="reset">重置(F6)</el-button>
      <el-button size="mini" type="primary" plain @click="close">取消(Esc)</el-button>
    </span>
    <SetDefaultFocus
      :visible.sync="setDefaultFocusVisible"
      :options="showFocusList"
      @setDefaultFocus="setDefaultFocus"
    />
    <SetDefaultProperty
      :visible.sync="setDefaultPropertyVisible"
      :options="propertiesOptions"
      :value="formData.property"
      @setDefaultProperty="setDefaultProperty"
    />
    <SetDefaultWarehouse
      :visible.sync="setDefaultWarehouseVisible"
      :options="warehouseOptions"
      :value="formData.warehouse"
      @setDefaultWarehouse="setDefaultWarehouse"
    />
    <AdvancedSettingBox :visible.sync="advancedSettingBoxVisible" :ALL_LIST="ALL_LIST" @setShowList="setShowList" />
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SetDefaultFocus from "./SetDefaultFocus";
import SetDefaultProperty from "./SetDefaultProperty";
import SetDefaultWarehouse from "./SetDefaultWarehouse";
import AdvancedSettingBox from "./AdvancedSettingBox";
import { getWarehousesList, getPropertiesList, getAccountsSetting } from "@/api/base";

let lastKeyScope;
const PART_CODE_WAY = {
  fuzzy: "模糊匹配",
  left: "左匹配",
  right: "右匹配",
  full: "全匹配"
};
const ALL_LIST = [
  { id: "code", name: "配件编码" },
  { id: "name", name: "配件名称" },
  { id: "vehModel", name: "车型" },
  { id: "brand", name: "配件品牌" },
  { id: "productionPlace", name: "产地" },
  { id: "productionCode", name: "生产码" },
  { id: "posCode", name: "部位码" },
  { id: "property", name: "配件性质" },
  { id: "warehouse", name: "所属仓库" },
  { id: "model", name: "规格" }
];
const defaultFormData = {
  code: "",
  codeMatch: "fuzzy",
  name: "",
  nameMatch: "fuzzy",
  vehModel: "",
  vehModelMatch: "fuzzy",
  brand: "",
  brandMatch: "full",
  productionPlace: "",
  productionPlaceMatch: "full",
  productionCode: "",
  productionCodeMatch: "fuzzy",
  posCode: "",
  posCodeMatch: "fuzzy",
  property: [],
  warehouse: [],
  model: "",
  modelMatch: "fuzzy"
};
export default {
  name: "AdvancedSetting",
  components: {
    SetDefaultFocus,
    SetDefaultProperty,
    SetDefaultWarehouse,
    AdvancedSettingBox
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      desc: "浏览商品-配件-高级查询设置",
      dialogVisible: false,
      setDefaultFocusVisible: false,
      setDefaultPropertyVisible: false,
      setDefaultWarehouseVisible: false,
      advancedSettingBoxVisible: false,
      keyScope: "advancedSetting",
      PART_CODE_WAY: PART_CODE_WAY,
      ALL_LIST: ALL_LIST,
      showList: [
        { id: "code", name: "配件编码", isMove: false },
        { id: "name", name: "配件名称", isMove: false },
        { id: "vehModel", name: "车型", isMove: true },
        { id: "brand", name: "配件品牌", isMove: true },
        { id: "productionPlace", name: "产地", isMove: true },
        { id: "productionCode", name: "生产码", isMove: true },
        { id: "posCode", name: "部位码", isMove: true },
        { id: "property", name: "配件性质", isMove: true },
        { id: "warehouse", name: "所属仓库", isMove: true },
        { id: "model", name: "规格", isMove: true }
      ],
      formData: JSON.parse(JSON.stringify(defaultFormData)),
      defaultFocus: "code",
      currentFocus: "",
      warehouseOptions: [],
      propertiesOptions: []
    };
  },
  computed: {
    ...mapGetters("companyInfo", ["noLoginLook"]),
    showFocusList() {
      return this.showList.filter(item => !["property", "warehouse"].includes(item.id));
    },
    showFocusListAll() {
      const list = this.showList.filter(item => !["property", "warehouse"].includes(item.id));
      list.push({ id: "confirm", name: "确定" });
      return list;
    }
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
      if (val) {
        this.resetFn();
        lastKeyScope = this.$hotkeys.getScope();
        this.$hotkeys.setScope(this.keyScope);
        this.init();
        document.addEventListener("keydown", this.bindKeyboard);
      } else {
        this.$hotkeys.setScope(lastKeyScope);
        document.removeEventListener("keydown", this.bindKeyboard);
      }
    },
    dialogVisible() {
      this.$emit("update:visible", this.dialogVisible);
    }
  },
  methods: {
    //清除上次的数据,记住上次的查询模式
    resetFn() {
      this.formData.code = "";
      this.formData.name = "";
      this.formData.vehModel = "";
      this.formData.productionPlace = "";
      this.formData.productionCode = "";
      this.formData.posCode = "";
      this.formData.property = [];
      this.formData.warehouse = [];
      this.formData.model = "";
    },
    init() {
      this.getAccountsSetting();
      this.getWarehouseOptions();
      this.getPropertiesOptions();
    },
    async getAccountsSetting() {
      if (this.noLoginLook) return;
      const data = await getAccountsSetting();
      this.defaultFocus = data.generalSearch.firstFocus;
      this.currentFocus = this.defaultFocus;
      if (data && data.generalSearch && data.generalSearch.showItems) {
        // 处理显示列表
        const showList = [];
        data.generalSearch.showItems.map(item => {
          for (const items of ALL_LIST) {
            if (item === items.id) {
              showList.push({
                ...items,
                isMove: !["code", "name"].includes(items.id)
              });
              break;
            }
          }
        });
        this.showList = showList;
      }
      this.setFormItemFocus();
    },
    async getWarehouseOptions() {
      this.warehouseOptions = (await getWarehousesList()) || [];
    },
    async getPropertiesOptions() {
      let data = (await getPropertiesList()) || [];
      data = data.map(item => {
        return {
          id: item,
          name: item
        };
      });
      this.propertiesOptions = data;
    },
    bindKeyboard(e) {
      if (this.$hotkeys.getScope() !== this.keyScope) {
        return;
      }
      switch (e.code) {
        case "Enter":
          e.preventDefault();
          this.confirm();
          break;
        case "F3":
          e.preventDefault();
          this.confirm();
          break;
        case "F6":
          e.preventDefault();
          this.reset();
          break;
        case "ArrowUp":
          e.preventDefault();
          this.updateFocusData("up");
          break;
        case "ArrowDown":
          e.preventDefault();
          this.updateFocusData("down");
          break;
        case "Tab":
          if (e.shiftKey) {
            e.preventDefault();
            this.updateFocusData("up");
          } else {
            e.preventDefault();
            this.updateFocusData("down");
          }
          break;
        default:
          return;
      }
    },
    close() {
      this.dialogVisible = false;
    },
    confirm() {
      const formData = window.tool.deepClone(this.formData);
      for (const key in formData) {
        if (this.formValidate({ [key]: formData[key] })) {
          return;
        }
      }
      if (this.formQueryEmpty(formData)) {
        return;
      }

      formData.properties = formData.property; // 性质
      formData.warehouses = formData.warehouse; // 仓库
      delete formData.property;
      delete formData.warehouse;

      this.close();
      this.$emit("change", formData);
    },
    reset() {
      this.formData = JSON.parse(JSON.stringify(defaultFormData));
    },
    setting() {
      this.advancedSettingBoxVisible = true;
    },
    clickDefaultCursor() {
      this.setDefaultFocusVisible = true;
    },
    clickSetDefaultProperty() {
      this.setDefaultPropertyVisible = true;
    },
    clickSetDefaultWarehouse() {
      this.setDefaultWarehouseVisible = true;
    },
    clickResetCondition() {
      this.formData = JSON.parse(JSON.stringify(defaultFormData));
    },
    updateFocusData(type) {
      if (type === "up") {
        const upIndex = this.showFocusListAll.findIndex(item => item.id === this.currentFocus);
        if (upIndex === 0) {
          this.currentFocus = this.showFocusListAll[this.showFocusListAll.length - 1].id;
        } else {
          this.currentFocus = this.showFocusListAll[upIndex - 1].id;
        }
        this.setFormItemFocus();
      }
      if (type === "down") {
        const downIndex = this.showFocusListAll.findIndex(item => item.id === this.currentFocus);
        if (downIndex === this.showFocusListAll.length - 1) {
          this.currentFocus = this.showFocusListAll[0].id;
        } else {
          this.currentFocus = this.showFocusListAll[downIndex + 1].id;
        }
        this.setFormItemFocus();
      }
    },
    setFormItemFocus() {
      setTimeout(() => {
        this.$nextTick(() => {
          if (this.currentFocus === "confirm") {
            this.$refs[this.currentFocus].$el.focus();
          } else {
            this.$refs[this.currentFocus][0]?.focus();
          }
        });
      }, 0);
    },
    autocompleteSearchPartName() {
      return [];
    },
    autocompleteSearchVehModel() {
      return [];
    },
    autocompleteSearchPartBrand() {
      return [];
    },
    autocompleteSearchProductionPlace() {
      return [];
    },
    setDefaultFocus(defaultFocus) {
      this.defaultFocus = defaultFocus;
      this.currentFocus = defaultFocus;
      this.setFormItemFocus();
    },
    setDefaultProperty(defaultProperties) {
      this.formData.property = defaultProperties;
    },
    setDefaultWarehouse(defaultWarehouse) {
      this.formData.warehouse = defaultWarehouse;
    },
    setShowList(showList) {
      this.showList = showList;
      this.setFormItemFocus();
    },
    formFocus(currentFocus) {
      this.currentFocus = currentFocus;
    },
    formValidate(obj = {}) {
      let message = "";
      if (obj.code && String(obj.code).length < 2) {
        message = "配件编码最少2位";
      } else if (obj.name && String(obj.name).length < 2) {
        message = "配件名称最少2位";
      } else if (obj.vehModel && String(obj.vehModel).length < 1) {
        message = "车型/适用车型最少1位";
      } else if (obj.brand && String(obj.brand).length < 1) {
        message = "配件品牌最少1位";
      } else if (obj.productionPlace && String(obj.productionPlace).length < 1) {
        message = "产地最少1位";
      } else if (obj.productionCode && String(obj.productionCode).length < 2) {
        message = "生产码最少2位";
      } else if (obj.posCode && String(obj.posCode).length < 2) {
        message = "部位码最少2位";
      }
      if (message) {
        this.$message.error(message);
        return true;
      } else {
        return false;
      }
    },
    formQueryEmpty(obj = {}) {
      // 不能为空值的字段，其他字段值任意，以下字段值全部为空时，提示用户输入查询条件
      const noEmptyField = [
        "code",
        "name",
        "vehModel",
        "brand",
        "productionPlace",
        "productionCode",
        "posCode",
        "model"
      ];
      let state = true;
      noEmptyField.map(item => {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(item) && obj[item]) {
          state = false;
        }
      });
      if (state) {
        this.$message.error("请输入更多查询条件");
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content-box {
  .content-box-form {
    padding: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid #aeaeae;
  }
  .el-form-item--mini.el-form-item {
    margin-bottom: 5px;
  }
}
</style>
