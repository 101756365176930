<template>
  <div ref="resizeBox" class="resize-box" :class="resizeBoxClass">
    <div ref="block1" class="block1">
      <slot name="block1" />
    </div>
    <div ref="resizeSlider" class="resize-slider" :class="isColumn ? 'resize-slider-column' : ''">
      <div class="flex1"></div>
      <div class="resize-slider-target" @click="setResize('direction')">
        <i :class="`el-icon-caret-${direction}`"></i>
      </div>
      <div class="flex1"></div>
    </div>
    <div ref="block2" class="block2 flex1">
      <slot name="block2" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ResizeBox",
  props: {
    isColumn: {
      type: Boolean,
      default: false
    },
    minLen: {
      type: Number,
      default: 0
    },
    // 占比
    blockOneProportion: {
      type: Number,
      default: 0.5
    },
    resizeBoxName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      desc: "伸缩盒子",
      positionLen: 0,
      direction: "right"
    };
  },
  computed: {
    // 控制方向
    resizeBoxClass() {
      return this.isColumn ? "resize-box-column" : "";
    }
  },
  mounted() {
    this.initListen();
  },
  methods: {
    initListen() {
      const resizeBox = this.$refs.resizeBox;
      const resizeDom = this.$refs.resizeSlider;
      const block1Dom = this.$refs.block1;
      // 设置默认值
      if (this.getResizeValue()) {
        this.setResize(this.getResizeValue());
      } else {
        this.setResize(resizeBox[this.isColumn ? "offsetHeight" : "offsetWidth"] * this.blockOneProportion);
      }
      resizeDom.onmousedown = e => {
        e.preventDefault();
        e.stopPropagation();
        let startLen;
        let beforeLen;
        if (this.isColumn) {
          startLen = e.clientY;
          beforeLen = block1Dom.offsetHeight;
        } else {
          startLen = e.clientX;
          beforeLen = block1Dom.offsetWidth;
        }

        document.onmousemove = e => {
          e.preventDefault();
          e.stopPropagation();
          const endLen = this.isColumn ? e.clientY : e.clientX;
          let positionLen = endLen - startLen + beforeLen;
          // 最小值
          if (positionLen < this.minLen) {
            positionLen = this.minLen;
          }
          // 最大值
          if (positionLen >= resizeBox[this.isColumn ? "offsetHeight" : "offsetWidth"] - 10) {
            positionLen = resizeBox[this.isColumn ? "offsetHeight" : "offsetWidth"] - 10;
          }
          this.setResize(positionLen);
        };
        document.onmouseup = e => {
          e.preventDefault();
          e.stopPropagation();
          document.onmousemove = null;
          document.onmouseup = null;
          resizeDom.releaseCapture && resizeDom.releaseCapture();
        };
        resizeDom.setCapture && resizeDom.setCapture();
      };
    },
    setResize(positionLen) {
      const resizeBox = this.$refs.resizeBox;
      const block1Dom = this.$refs.block1;

      if (positionLen === "direction") {
        // 点击方向
        if (this.isColumn) {
          if (this.direction === "top") {
            if (this.positionLen > resizeBox.offsetHeight * this.blockOneProportion) {
              // 向上时上盒子高度大于默认比例时，回到占比值
              this.setResize(resizeBox.offsetHeight * this.blockOneProportion);
              return;
            } else {
              // 向上时设置为0
              positionLen = 0;
            }
          } else {
            // 向下时回到占比值
            this.setResize(resizeBox.offsetHeight * this.blockOneProportion);
            return;
          }
        } else {
          if (this.direction === "right") {
            if (this.positionLen < resizeBox.offsetWidth * this.blockOneProportion) {
              // 向右时左侧盒子宽度小于默认比例时，回到占比值
              this.setResize(resizeBox.offsetWidth * this.blockOneProportion);
              return;
            } else {
              // 向右时设置为盒子最大宽度
              positionLen = resizeBox.offsetWidth - 10;
            }
          } else {
            // 向左时回到占比值
            this.setResize(resizeBox.offsetWidth * this.blockOneProportion);
            return;
          }
        }
      }

      // 设置模块1的宽高
      block1Dom.style[this.isColumn ? "height" : "width"] = positionLen + "px";
      this.positionLen = positionLen;

      // 图标方向
      if (this.isColumn) {
        if (this.positionLen > 2) {
          this.direction = "top";
        } else {
          this.direction = "bottom";
        }
      } else {
        if (this.positionLen < resizeBox.offsetWidth - 12) {
          this.direction = "right";
        } else {
          this.direction = "left";
        }
      }
      this.setResizeValue();
    },
    setResizeValue() {
      if (this.resizeBoxName) {
        localStorage.setItem(this.resizeBoxName, String(this.positionLen || 0));
      }
    },
    getResizeValue() {
      return Number(localStorage.getItem(this.resizeBoxName) || 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.resize-box {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  .resize-slider {
    width: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #c9ced4;
    cursor: col-resize;
    .resize-slider-target {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #185e91;
      border-radius: 5px;
      cursor: pointer;
      i {
        color: white;
        font-size: 16px;
      }
    }
  }
  .resize-slider-column {
    width: 100%;
    height: 10px;
    display: flex;
    flex-direction: row;
    cursor: row-resize;
    .resize-slider-target {
      width: 50px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #185e91;
      border-radius: 5px;
      cursor: pointer;
      i {
        color: white;
        font-size: 16px;
      }
    }
  }
  .block1,
  .block2 {
    overflow: hidden;
    position: relative;
  }
}
.resize-box-column {
  flex-direction: column;
}
</style>
