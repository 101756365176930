export const PARTS_LIST_TABLE = {
  tableName: "PartsListTable",
  tableNameTwo: "PartsListExchangeTable",
  columns: [
    {
      label: "操作",
      prop: "control",
      width: 50,
      align: "center",
      fixed: "left"
    },
    {
      label: "订货数量",
      prop: "orderQuantity",
      width: 140
    },
    {
      label: "配件编码",
      prop: "code",
      width: 120,
      attrs: {
        sortable: "custom"
      }
    },
    {
      label: "配件名称",
      prop: "name",
      width: 220
    },
    {
      label: "车型",
      prop: "vehModel",
      width: 100
    },
    {
      label: "品牌",
      prop: "brand",
      width: 100
    },
    {
      label: "产地",
      prop: "productionPlace",
      width: 100
    },
    {
      label: "库存",
      prop: "qty",
      width: 100,
      attrs: {
        sortable: "custom"
      }
    },
    // {
    //   label: "订货库",
    //   prop: "bookQty",
    //   width: 100,
    //   attrs: {
    //     sortable: "custom"
    //   }
    // },
    {
      label: "价格",
      prop: "price",
      width: 100,
      attrs: {
        sortable: "custom"
      }
    },
    {
      label: "仓库",
      prop: "warehouse",
      width: 100
    },
    {
      label: "生产批号",
      prop: "productBatch",
      width: 100
    },
    {
      label: "配件分类",
      prop: "category",
      width: 100
    },
    {
      label: "配件性质",
      prop: "property",
      width: 80
    },
    {
      label: "规格",
      prop: "model",
      width: 80
    },
    {
      label: "生产码",
      prop: "productionCode",
      width: 100
    }
  ]
};
