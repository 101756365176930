<template>
  <el-dialog
    title="快速组合搜索设置"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="550px"
    top="10vh"
    @close="close"
  >
    <div class="content-box">
      <div class="flex flex-center">
        <span>分隔符：</span>
        <el-select class="flex1" v-model="separatorValue" size="mini" placeholder="请选择分隔符">
          <el-option v-for="item in separatorOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="m-t-10">
        <HtTable
          ref="partsKeyWordSearch"
          v-bind="$attrs"
          v-on="$listeners"
          :data="tableData"
          :columns="columns"
          :table-name="tableName"
          :key-scope="tableName"
          :auto-height="true"
          @hotkeysUp="handleKeyCode"
        />
      </div>
      <div class="m-t-10 flex flex-center">
        <el-button @click="addRow" size="mini" :disabled="tableData.length >= 6" class="width-full flex flex-center">
          <div class="flex flex-center">
            <i class="el-icon-circle-plus font-size-16"></i>
            <span>添加</span>
          </div>
        </el-button>
      </div>
      <div class="m-t-10">
        <div class="flex">
          <div>设置说明：</div>
          <div class="flex1">
            1、请至少选择1个条件进行组合。<br />
            2、组合条件将生成检索码，进行模糊匹配
          </div>
        </div>
      </div>
      <div class="m-t-10">
        <div class="flex">
          <div>匹配说明：</div>
          <div class="flex1">
            1、配件编码字段匹配【配件编码】【图号】【生产码】【部位码】<br />【标准编码】【互换码】【供应商编码】。<br />
            2、配件名称字段匹配【配件名称】【名称助记符】<br />【英文名称】【标准名称】。<br />
            3. 车型字段匹配【车型】【适用车型】【标准车型】
          </div>
        </div>
      </div>
    </div>
    <span slot="footer">
      <el-button size="mini" type="primary" plain @click="confirm">确定(F3)</el-button>
      <el-button size="mini" type="primary" plain @click="close">取消(Esc)</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {getAccountsSetting, updateAccountsSetting} from "@/api/base";

let lastKeyScope;
export default {
  name: "PartsKeyWordSearch",
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      desc: "浏览商品-配件-快速组合搜索设置",
      dialogVisible: false,
      keyScope: "partsKeyWordSearch",
      tableName: "partsKeyWordSearchTable",
      separatorValue: "",
      separatorOptions: [
        { value: "空格", label: "空格" },
        { value: "-", label: "-" },
        { value: "+", label: "+" },
        { value: ",", label: "," },
        { value: ";", label: ";" }
      ],
      tableData: [],
      fieldNameOptions: [
        { value: "code", label: "编码" },
        { value: "name", label: "名称" },
        { value: "brand", label: "品牌" },
        { value: "productionPlace", label: "产地" },
        { value: "category", label: "分类" },
        { value: "vehModel", label: "车型" },
        { value: "model", label: "规格" }
      ],
      info: {}
    };
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
      if (val) {
        lastKeyScope = this.$hotkeys.getScope();
        this.$hotkeys.setScope(this.keyScope);
        this.bindKeyboard();
        this.getAccountsSetting();
      } else {
        this.$hotkeys.deleteScope(this.keyScope);
        this.$hotkeys.setScope(lastKeyScope);
      }
    },
    dialogVisible() {
      this.$emit("update:visible", this.dialogVisible);
    },
    tableData: {
      deep: true,
      immediate: true,
      handler(value) {
        this.$emit("change", "tableData", value);
        const fieldNameValueArr = value.map(item => item.fieldNameValue);
        for (const item of this.fieldNameOptions) {
          item.disabled = !!fieldNameValueArr.includes(item.value);
        }
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          label: "操作",
          prop: "control",
          width: 100,
          align: "center",
          render: (value, row, index) => {
            return (
              <div class="cursor-pointer">
                <i on-click={e => this.deleteRow(row, index, e)} class="el-icon-remove font-size-18 text-danger" />
              </div>
            );
          }
        },
        {
          label: "字段名称",
          prop: "fieldNameValue",
          align: "center",
          width: 300,
          attrs: {
            showOverflowTooltip: false
          },
          render: (value, row, index) => {
            return (
              <el-select
                v-model={this.tableData[index].fieldNameValue}
                size="mini"
                placeholder="请选择"
                class="width-full"
              >
                {this.fieldNameOptions.map(item => {
                  return <el-option key={item.value} disabled={item.disabled} label={item.label} value={item.value} />;
                })}
              </el-select>
            );
          }
        }
      ];
    }
  },
  methods: {
    async getAccountsSetting() {
      this.info = await getAccountsSetting();
      this.separatorValue = this.info.keywordSearch.separator;
      this.tableData = this.info.keywordSearch.fields.map(item => {
        return {
          fieldNameValue: item
        };
      });
    },
    bindKeyboard() {
      this.$hotkeys("f3", { scope: this.keyScope }, this.handleKeyCode);
    },
    handleKeyCode(e) {
      switch (e.code) {
        case "F3":
          e.preventDefault();
          this.confirm();
          break;
        default:
          return;
      }
    },
    close() {
      this.dialogVisible = false;
    },
    confirm() {
      const data = this.info;
      data.keywordSearch.separator = this.separatorValue;
      data.keywordSearch.fields = this.tableData.map(item => item.fieldNameValue);
      updateAccountsSetting(data)
        .then(() => {
          this.$message.success("快速组合搜索设置设置成功！");
          this.close();
        })
        .catch(err => {
          this.$message.error(err.message || "更新失败");
        });
    },
    addRow() {
      this.tableData.push({});
    },
    deleteRow(row, index, e) {
      e.stopPropagation();
      if (this.tableData.length < 2) {
        this.$message.warning({ message: "至少保留1个字段" });
      } else {
        this.tableData.splice(index, 1);
      }
    }
  }
};
</script>
