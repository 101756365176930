<template>
  <el-dialog
    title="默认查询仓库设置"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="400px"
    @close="close"
  >
    <div class="content-box">
      <div>请选择默认查询仓库</div>
      <ht-select
        class="width-full m-t-20"
        v-model="defaultWarehouse"
        :options="options"
        id="depotNo"
        name="depotName"
        clearable
        filterable
        multiple
        has-selecte-all-val
        collapse-tags
        size="mini"
        placeholder="请选择默认查询仓库"
      />
    </div>
    <span slot="footer">
      <el-button size="mini" type="primary" plain @click="confirm">确定(F3)</el-button>
      <el-button size="mini" type="primary" plain @click="close">取消(Esc)</el-button>
    </span>
  </el-dialog>
</template>

<script>
let lastKeyScope;
export default {
  name: "SetDefaultWarehouse",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      desc: "浏览商品-配件-高级查询设置-默认查询仓库设置",
      dialogVisible: false,
      keyScope: "setDefaultWarehouse",
      defaultWarehouse: []
    };
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
      if (val) {
        this.defaultWarehouse = this.value;
        lastKeyScope = this.$hotkeys.getScope();
        this.$hotkeys.setScope(this.keyScope);
        this.bindKeyboard();
      } else {
        this.$hotkeys.deleteScope(this.keyScope);
        this.$hotkeys.setScope(lastKeyScope);
      }
    },
    dialogVisible() {
      this.$emit("update:visible", this.dialogVisible);
    }
  },
  methods: {
    bindKeyboard() {
      this.$hotkeys("f3", { scope: this.keyScope }, (e, handler) => {
        switch (handler.key) {
          case "f3":
            e.preventDefault();
            this.confirm();
            break;
          default:
            return;
        }
      });
    },
    close() {
      this.dialogVisible = false;
    },
    confirm() {
      this.$message.success("仓库默认值设置成功！");
      this.$emit("setDefaultWarehouse", this.defaultWarehouse);
      this.close();
    }
  }
};
</script>

<style lang="scss" scoped></style>
