<template>
  <div class="width-full height-full overflow-hidden">
    <HtTable ref="partsListTable" :data="tableData" :columns="columns"> </HtTable>
  </div>
</template>

<script>
export default {
  name: "ApplicableVehicleType",
  props: {
    rows: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      columns: [
        {
          label: "车型",
          prop: "name"
        }
      ]
    };
  },
  computed: {
    tableData() {
      const data = window.tool.deepClone(this.rows);
      if (data.length) {
        return [
          {
            name: data[0].generalVehModels
          }
        ];
      } else {
        return [];
      }
    }
  }
};
</script>

<style scoped></style>
