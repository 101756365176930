<template>
  <el-dialog
    title="光标默认锁定设置"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="400px"
    @close="close"
  >
    <div class="content-box">
      <div>请选择光标默认锁定参数</div>
      <el-select
        class="width-full m-t-20"
        v-model="defaultFocus"
        size="mini"
        clearable
        placeholder="请选择光标默认锁定参数"
      >
        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
      </el-select>
    </div>
    <span slot="footer">
      <el-button size="mini" type="primary" plain @click="confirm">确定(F3)</el-button>
      <el-button size="mini" type="primary" plain @click="close">取消(Esc)</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getAccountsSetting, updateAccountsSetting } from "@/api/base";

let lastKeyScope;
export default {
  name: "SetDefaultFocus",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      desc: "浏览商品-配件-高级查询设置-设置默认光标",
      dialogVisible: false,
      keyScope: "setDefaultFocus",
      defaultFocus: ""
    };
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
      if (val) {
        lastKeyScope = this.$hotkeys.getScope();
        this.$hotkeys.setScope(this.keyScope);
        this.bindKeyboard();
        this.getAccountsSetting();
      } else {
        this.$hotkeys.deleteScope(this.keyScope);
        this.$hotkeys.setScope(lastKeyScope);
      }
    },
    dialogVisible() {
      this.$emit("update:visible", this.dialogVisible);
    }
  },
  methods: {
    async getAccountsSetting() {
      this.info = await getAccountsSetting();
      this.defaultFocus = this.info.generalSearch.firstFocus;
    },
    bindKeyboard() {
      this.$hotkeys("f3", { scope: this.keyScope }, (e, handler) => {
        switch (handler.key) {
          case "f3":
            e.preventDefault();
            this.confirm();
            break;
          default:
            return;
        }
      });
    },
    close() {
      this.dialogVisible = false;
    },
    confirm() {
      const data = this.info;
      data.generalSearch.firstFocus = this.defaultFocus;
      updateAccountsSetting(data)
        .then(() => {
          this.$message.success("光标默认锁定设置成功！");
          this.$emit("setDefaultFocus", this.defaultFocus);
          this.close();
        })
        .catch(err => {
          this.$message.error(err.message || "更新失败");
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
