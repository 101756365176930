import request from "@/utils/request";

// 商品查询
export function getGoodsList(params) {
  return request({
    url: "/mall/goods/search",
    method: "post",
    data: params
  });
}

// 商品详情
export function getGoodsDetail(params) {
  return request({
    url: "/mall/goods/detail",
    method: "get",
    params: params
  });
}

// 商品详情(从订单调过来的)
export function getOrderDetailsById(params) {
  return request({
    url: `/mall/order-details/${params.id}`,
    method: "get"
  });
}
